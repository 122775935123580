import {ChangeDetectionStrategy, Component, HostListener, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../../../services';
import {Router} from '@angular/router';
import {AppState} from '../../../../../store/state';
import {Store} from '@ngrx/store';
import {CloseModal, LogIn, OpenModal, SetAuthenticationError} from '../../../../../store/actions';
import {emailPattern} from '../../../../../utils';
import {ModalTypes} from '../../../../../enums';
import {selectAuthenticationError} from '../../../../../store/selectors';
import {BehaviorSubject, Observable} from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginModalComponent implements OnInit {

  public loginForm: FormGroup;
  public isSubmitted: boolean;
  public error: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public passwordFieldType = 'password';

  @HostListener('document:touchstart', ['$event'])
  onStart(event) {
    if (event.touches && event.type === 'touchstart' && event.target.id === 'sp') {
      this.showPassword();
    }
    if (event.touches && event.type === 'touchend' && event.target.id === 'sp') {
      this.hidePassword();
    }
  }

  constructor(private auth: AuthService,
              private router: Router,
              private frmBuilder: FormBuilder,
              private deviceService: DeviceDetectorService,
              private store: Store<AppState>) {
  }

  ngOnInit() {
    this.loginForm = this.frmBuilder.group({
      email: ['', [Validators.required, Validators.pattern(emailPattern)]],
      password: ['', [Validators.required]]
    });
    this.store.select(selectAuthenticationError).subscribe(error => {
      this.error.next(error);
    });

    if (window.location.href.includes('wejscie-do-profilu')) {
      this.forgetPassword();
    }
  }

  get email() {
    return this.loginForm.get('email');
  }

  get password() {
    return this.loginForm.get('password');
  }

  public showPassword = () => this.passwordFieldType = 'text';

  public hidePassword = () => this.passwordFieldType = 'password';

  public login() {
    this.store.dispatch(new SetAuthenticationError({error: ''}));
    this.isSubmitted = true;

    const {email, password} = this.loginForm.value;

    const isMobile = this.deviceService.isMobile();

    this.store.dispatch(new LogIn({
      username: email,
      password,
      isMobile
    }));
  }

  public forgetPassword() {
    this.store.dispatch(new OpenModal(ModalTypes.ForgetPassModal));
  }

  public unsubmit(event) {
    this.isSubmitted = false;
/*
    if (event.target.id === 'loginPass') {
      if (this.loginForm.controls.password.value.length > 0) {

        this.loginForm.controls.password.setValue(this.loginForm.controls.password.value[0].toLowerCase() +
            this.loginForm.controls.password.value.substr(1, this.loginForm.controls.password.value.length - 1));
      }
    }
*/

    if (event.target.id === 'loginMail') {
      if (this.loginForm.controls.email.value.length > 0) {

        this.loginForm.controls.email.setValue(this.loginForm.controls.email.value[0].toLowerCase() +
            this.loginForm.controls.email.value.substr(1, this.loginForm.controls.email.value.length - 1));
      }
    }
  }

  public close = () => this.store.dispatch(new CloseModal());

}
