// import { ChangeUserPassword } from './../actions/user.actions';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  CallConfirmLoanNotification,
  ChangeEmail,
  ChangeEmailError,
  ChangeEmailSuccess,
  ChangeAddress,
  ChangeAddressError,
  ChangeAddressSuccess,
  ChangeICNumber,
  ChangeICNumberSuccess,
  ChangeICNumberError,
  ChangePhone,
  ChangePhoneError,
  ChangePhoneSuccess,
  ChangeUserPassword,
  CloseModal,
  CloseUserPopup,
  ConfirmEmailChange,
  ConfirmPhoneChange,
  CreateLoanRefinanceRequest,
  CreateLoanRequest,
  DeleteLoanRequest,
  DeleteLoanRequestSuccess,
  LoadConditions,
  LoadLoanCurrentInfo, LoadLoanCurrentInfoSuccess,
  LoadLoanError,
  LoadLoanHistory,
  LoadLoanSchedule,
  LoadLoanState,
  LoadRefinanceOptions,
  LoadUserData, LoadUserDataSuccess,
  LoanRequestError,
  LoanRequestSuccess,
  AffiliateCompleteRegistration,
  AffiliateCompleteRegistrationSuccess,
  LoanSmsVerification,
  LoanSmsVerificationError,
  LoanSmsVerificationSuccess,
  LogOut,
  MarketingSubscriptionError,
  MarketingSubscriptionSuccess, OpenModal,
  RefinanceRequestError,
  RefinanceRequestSuccess,
  RequestSmsCode,
  RequestSmsCodeSuccess,
  RequestSmsError,
  RequestVerificationCodeAgain,
  SetAcceptanceText,
  SetAuthenticationError,
  SetAuthenticationStatus,
  SetChangingState, SetCommonErrorMessage, SetCommonSuccessMessage,
  SetCurrentRefinanceOptions, SetLoanConfirmationSmsRequestedStatus,
  SetLoanCurrentInfo,
  SetLoanHistory, SetLoanHistoryLoadedStatus,
  SetLoanSchedule,
  SetLoanSmsVerificationStatus,
  SetLoanState,
  SetRefinanceOptions,
  SetUserData,
  ToggleMarketingSubscription,
  UserActionTypes,
  LogIn,
  SetFormFieldErrors
} from '../actions';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { ApiService, AuthService, DataService, SecureHttpClientService } from '../../services';
import { of } from 'rxjs';
import { RefinanceRequestType } from '../../modules/user-panel/models/refinance-types';
import { AppState } from '../state';
import { Store } from '@ngrx/store';
import { selectAuthenticatedToken, selectFullUserData, selectLoanCurrentInfo, selectUser } from '../selectors';
import { ChangingStateTypes } from '../../modules/user-panel/models/changing-state-types';
import { AccountPopupTypes } from '../../modules/user-panel/models/account-popup-types';
import { Router } from '@angular/router';
import {
  LoadUserNotifications,
  SetLoanVerificationFormSubmittedStatus,
  SetUserNotifications
} from '../actions/user.actions';
import * as _ from 'lodash';
import { ModalTypes } from '../../enums';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { globalVariables } from 'src/environments/globalVariables';

declare function getElementById(id): any;
declare function getHeaders(): any;
declare function getCookies(): any;
declare function getIp(): any;


@Injectable()
export class UserEffects {

  @Effect()
  loadUserData$ = this.actions$
    .pipe(
      ofType<LoadUserData>(UserActionTypes.LoadUserData),
      mergeMap((action) => this.authService.getUserData(action.payload.token)
        .pipe(
          withLatestFrom(
            this.store.select(selectFullUserData),
            this.store.select(selectLoanCurrentInfo)),

          switchMap(([resp, fullUserData,currentInfo]) => {
            const token = action.payload.token;

            let action1;

            if (fullUserData && !_.isEqual(fullUserData, resp.client)) {
              console.log('user not equal');
              action1 = new SetUserData({ userData: resp.client });
            } else if (!fullUserData) {
              action1 = new SetUserData({ userData: resp.client });
            } else {
              action1 = new LoadUserDataSuccess();
            }

            const action2 = new CloseModal();


            let action3 = null;

            if (token) {
              action3 = new SetAuthenticationStatus({ isAuthenticated: true, token });
            } else {
              action3 = new SetAuthenticationStatus({ isAuthenticated: false, token });
            }

            // @TODO revamp approach later

            let redirect = false;
            if (!this.authService.getAuthToken()) {
              redirect = true;
            }

            token ?
              this.authService.setAuthToken(token) :
              this.authService.setAuthToken(null);

            token ?
              this.authService.setExpiredToken((String)((new Date()).getTime() + environment.tokenExpireTime)) :
              this.authService.setExpiredToken(null);

            if (!window.location.href.includes('profil') && token) {

              if (redirect || !window.location.href.includes('zgoda_marketing') &&
                !window.location.href.includes('zgoda_elektronic') &&
                !window.location.href.includes('klauzula_informacyjna') &&
                !window.location.href.includes('regulamin') &&
                !window.location.href.includes('zgoda_marketing_partner') &&
                !window.location.href.includes('warunki-umowy') &&
                !window.location.href.includes('upowaznienie') &&
                !window.location.href.includes('zgoda-big') &&
                !window.location.href.includes('oswiadczenie-pep') &&
                !window.location.href.includes('zgoda-klienta') &&
                !window.location.href.includes('polityka-prywatnosci') &&
                !window.location.href.includes('akceptacja-warunkow') &&
                !window.location.href.includes('upowaznienie')) {

                  let isWaitingDecision = (currentInfo.status === currentInfo.Requested ||
                    currentInfo.status === currentInfo.Approved && currentInfo)
                  
                  if (isWaitingDecision || currentInfo.activeLoan) {
                    this.router.navigate(['/profil/moje-pozyczki']);
                  } else {
                  
                    this.router.navigate(['/profil/prosba-pozyczki']);
                  }

              }
            }
            const action4 = new LoadConditions();

            return of(action1, action2, action3, action4);
          }),
          catchError(errMessage => of(new SetAuthenticationError({ error: errMessage.error })))
        )
      )
    );

  @Effect()
  loadLoanSchedule$ = this.actions$
    .pipe(
      ofType<LoadLoanSchedule>(UserActionTypes.LoadLoanSchedule),
      mergeMap((action) => this.apiService.get('loan/schedule', {}, this.authService.getAuthHeaders())
        .pipe(
          map((schedule: any[]) => new SetLoanSchedule({ loanSchedule: schedule })),
          catchError(errMessage => of(new LoadLoanError({ errMessage: errMessage.error })))
        )
      )
    );

  @Effect()
  loadLoanState$ = this.actions$
    .pipe(
      ofType<LoadLoanState>(UserActionTypes.LoadLoanState),
      mergeMap((action) => this.apiService.get('loan/state', {}, this.authService.getAuthHeaders())
        .pipe(
          map((state: any) => new SetLoanState({ loanState: state })),
          catchError(errMessage => of(new LoadLoanError({ errMessage: errMessage.error })))
        )
      )
    );

  @Effect()
  loadLoanCurrentInfo$ = this.actions$
    .pipe(
      ofType<LoadLoanCurrentInfo>(UserActionTypes.LoadLoanCurrentInfo),
      withLatestFrom(this.store.select(selectAuthenticatedToken)),
      mergeMap(([action, token]) => {

        if (this.authService.getAuthToken()) {
          if (this.authService.getExpiredToken() &&
            (new Date()).getTime() < (Number(this.authService.getExpiredToken()))) {
            this.authService.setExpiredToken((String)((new Date()).getTime() + environment.tokenExpireTime));
          } else {
            this.store.dispatch(new LogOut());
            this.store.dispatch(new SetAuthenticationStatus({ isAuthenticated: false, token: null }));
          }
        }

        return this.apiService.post(`${environment.AppID}/GetActiveLoan`, { accessToken: token });
      }),
      withLatestFrom(this.store.select(selectAuthenticatedToken)),
      switchMap(([currentInfo, storeLoanInfo]) => {
        const failedLoanObject = { status: 'Rejected', takeALoan: true };

        if (globalVariables.blueMediaRedirect) {
          globalVariables.blueMediaRedirect = false;

          if (!currentInfo.activeLoan) {
            this.router.navigate(['/profil/prosba-pozyczki']);
          } else {
            this.store.dispatch(new SetCommonErrorMessage({ errMessage: 'Coś poszło nie tak... <br>Wystąpił nieoczekiwany błąd. Wróć na stronę główną i spróbuj ponownie.' }));
            this.store.dispatch(new OpenModal(ModalTypes.RegErrorModal));
          }
        }

        if (!currentInfo.success && !_.isEqual(storeLoanInfo, failedLoanObject)) {
          console.log('loan not equal');
          return of(new SetLoanCurrentInfo({ loanCurrentInfo: failedLoanObject }));
        }

        if (currentInfo.activeLoan.schedule === undefined)
          currentInfo.activeLoan.schedule = false;

        if (storeLoanInfo && currentInfo.activeLoan && !_.isEqual(storeLoanInfo, currentInfo.activeLoan)) {
          return of(new SetLoanCurrentInfo({ loanCurrentInfo: { ...currentInfo.activeLoan } }));
        }
        return of(new LoadLoanCurrentInfoSuccess());
      }),
      catchError(errMessage => of(new LoadLoanError({ errMessage: errMessage.error })))
    );


  @Effect()
  loadRefinanceOptions$ = this.actions$
    .pipe(
      ofType<LoadRefinanceOptions>(UserActionTypes.LoadRefinanceOptions),
      switchMap((action) => {
        let data = {};
        if (action.payload && action.payload.loanNumber) {
          const { loanNumber, amount, bankListType } = action.payload;
          data = {
            loanNumber,
            amount,
            bankListType,
          };
        }
        return of({
          refinanceOptions: [
            {
              term: 7,
              fee: 7,
              newMaturityDate: new Date(),
            },
            {
              term: 15,
              fee: 15,
              newMaturityDate: new Date(),
            },
            {
              term: 30,
              fee: 30,
              newMaturityDate: new Date(),
            },
          ],
          refinanceAmount: 1000,
        })
          .pipe(
            map((options: any) => new SetRefinanceOptions({ refinanceOptions: options })),
            catchError(errMessage => of(new LoadLoanError({ errMessage: errMessage.error })))
          );
        // return this.apiService.get('loanrequest/refinance-options', data, this.authService.getAuthHeaders())
        //   .pipe(
        //     map((options: any) => new SetRefinanceOptions({refinanceOptions: options})),
        //     catchError(errMessage => of(new LoadLoanError({errMessage: errMessage.error})))
        //   );
      }),
    );

  @Effect()
  createLoanRefinanceRequest$ = this.actions$
    .pipe(
      ofType<CreateLoanRefinanceRequest>(UserActionTypes.CreateLoanRefinanceRequest),
      switchMap(action => this.secHttp.post('loanrequest/refinance', action.payload.requestData, this.authService.getAuthHeaders())
        .pipe(
          switchMap(resp => {
            const action1 = new RefinanceRequestSuccess();
            const action2 = new CloseUserPopup();
            const action3 = new SetCurrentRefinanceOptions({
              term: action.payload.requestData.extensionTerm,
              type: RefinanceRequestType.WaitingForDecision
            });
            return of(action1, action2, action3);
          }),
          catchError(errMessage => {
            if (errMessage.error && errMessage.error.ClientId) {
              alert([errMessage.error.ClientId]);
            } else {
              alert('Wystąpił błąd podczas żądania refinansowania. Spróbuj ponownie.');
            }
            return of(new RefinanceRequestError({ errMessage: errMessage.error }));
          })
        )
      ),
    );

  @Effect()
  createLoanRequest$ = this.actions$
    .pipe(
      ofType<CreateLoanRequest>(UserActionTypes.CreateLoanRequest),
      switchMap(action => this.apiService.post(`${environment.AppID}/RequestLoan`, action.payload.requestData)
        .pipe(
          switchMap((resp: any) => {

            this.spinner.hide();

            if (resp.success) {
              const action1 = new LoanRequestSuccess({ newId: resp.loanId });

              if (this.authService.getAuthToken()) {
                if (this.authService.getExpiredToken() &&
                  (new Date()).getTime() < (Number(this.authService.getExpiredToken()))) {
                  this.authService.setExpiredToken((String)((new Date()).getTime() + environment.tokenExpireTime));
                } else {
                  this.store.dispatch(new LogOut());
                  this.store.dispatch(new SetAuthenticationStatus({ isAuthenticated: false, token: null }));
                }
              }

              const action2 = new LoadUserData({ token: this.authService.getAuthToken() });
              const action3 = new LoadLoanCurrentInfo();
              const action4 = new SetLoanConfirmationSmsRequestedStatus(false);
              const action5 = new SetLoanSmsVerificationStatus({ status: false });
              return of(action1, action2, action3, action4, action5);
            } else {

              if (resp.errorMessage !== 'INVALID_ACCESS_TOKEN') {
                this.store.dispatch(new SetCommonErrorMessage({ errMessage: resp.errorMessage }));
                this.store.dispatch(new OpenModal(ModalTypes.RegErrorModal));
              }

              //   alert(resp.errorMessage);
              return of(new LoanRequestError({ errMessage: resp.errorMessage }));
            }
          }),
          catchError(errMessage => {
            if (errMessage.error && errMessage.error.ClientId) {
              alert([errMessage.error.ClientId]);
            }
            return of(new LoanRequestError({ errMessage: errMessage.error }));
          })
        )
      ),
    );

  @Effect()
  deleteLoanRequest$ = this.actions$
    .pipe(
      ofType<DeleteLoanRequest>(UserActionTypes.DeleteLoanRequest),
      switchMap(action => this.secHttp.delete('/loanRequest/refinance', this.authService.getAuthHeaders())
        .pipe(
          switchMap(resp => {
            const action1 = new DeleteLoanRequestSuccess();
            const action2 = new SetCurrentRefinanceOptions({ term: null, fee: null, type: RefinanceRequestType.None });
            return of(action1, action2);
          }),
          catchError(errMessage => {
            alert('Wystąpił błąd podczas anulowania refinansowania. Spróbuj ponownie.');
            return of(new LoanRequestError({ errMessage: errMessage.error }));
          })
        )
      )
    );

  @Effect()
  changeEmail$ = this.actions$
    .pipe(
      ofType<ChangeEmail>(UserActionTypes.ChangeEmail),
      withLatestFrom(this.store.select(selectAuthenticatedToken)),
      switchMap(([action, token]) => this.apiService.post(`${environment.AppID}/ChangeEMailSendCode`,
        { emailAddress: action.payload.newEmail, captchaResponce: action.payload.captchaResponce, accessToken: token })
        .pipe(
          map(resp => {
            if (resp.success) {
              return new ChangeEmailSuccess({ state: ChangingStateTypes.Confirm });
            } else {
              return new ChangeEmailError({ errMessage: resp.errorMessage, state: ChangingStateTypes.Error });
            }
          }),
          catchError(errMessage => of(new ChangeEmailError({ errMessage, state: ChangingStateTypes.Error })))
        )
      )
    );

  @Effect()
  confirmEmailChange$ = this.actions$
    .pipe(
      ofType<ConfirmEmailChange>(UserActionTypes.ConfirmEmailChange),
      withLatestFrom(this.store.select(selectAuthenticatedToken)),
      mergeMap(([action, token]) => this.apiService.post(`${environment.AppID}/ChangeEMailConfirm`,
        { emailAddress: action.payload.newEmail, confirmation_code: action.payload.code, accessToken: token }
      )
        .pipe(
          map(resp => {
            if (resp.success) {
              return new ChangeEmailSuccess({ state: ChangingStateTypes.Success, newEmail: action.payload.newEmail });
            } else {
              return new ChangeEmailError({ errMessage: resp.errorMessage, state: ChangingStateTypes.Error });
            }
          }),
          catchError(errMessage => of(new ChangeEmailError({ errMessage, state: ChangingStateTypes.Error })))
        )
      )
    );


  @Effect()
  ChangeAddress$ = this.actions$
    .pipe(
      ofType<ChangeAddress>(UserActionTypes.ChangeAddress),
      withLatestFrom(this.store.select(selectAuthenticatedToken)),
      switchMap(([action, token]) => this.apiService.post(`${environment.AppID}/ChangeAddress`,
        {
          Residence: {
            city: action.payload.city,
            street: action.payload.street,
            house: action.payload.house,
            apartment: action.payload.apartment,
            postalCode: action.payload.postalCode
          },
          ActualResidence: action.payload.actualResidence,
          captchaResponce: action.payload.captchaResponce, accessToken: token
        })
        .pipe(
          map(resp => {
            if (resp.success) {
              return new ChangeAddressSuccess({
                state: ChangingStateTypes.Success,
                actualResidence: action.payload.actualResidence,
                city: action.payload.city,
                street: action.payload.street,
                house: action.payload.house,
                apartment: action.payload.apartment,
                postalCode: action.payload.postalCode
              });
            } else {
              return new ChangeAddressError({ errMessage: resp.errorMessage, state: ChangingStateTypes.Error });
            }
          }),
          catchError(errMessage => of(new ChangeAddressError({ errMessage, state: ChangingStateTypes.Error })))
        )
      )
    );






  @Effect()
  ICNumber$ = this.actions$
    .pipe(
      ofType<ChangeICNumber>(UserActionTypes.ChangeICNumber),
      withLatestFrom(this.store.select(selectAuthenticatedToken)),
      switchMap(([action, token]) => this.apiService.post(`${environment.AppID}/ChangeICNumber`,
        { ICNumber: action.payload.ICNumber, captchaResponce: action.payload.captchaResponce, accessToken: token })
        .pipe(
          map(resp => {
            if (resp.success) {
              return new ChangeICNumberSuccess({ state: ChangingStateTypes.Success, ICNumber: action.payload.ICNumber });
            } else {
              return new ChangeICNumberError({ errMessage: resp.errorMessage, state: ChangingStateTypes.Error });
            }
          }),
          catchError(errMessage => of(new ChangeICNumberError({ errMessage, state: ChangingStateTypes.Error })))
        )
      )
    );

  @Effect()
  changePhone$ = this.actions$
    .pipe(
      ofType<ChangePhone>(UserActionTypes.ChangePhone),
      withLatestFrom(this.store.select(selectAuthenticatedToken)),
      switchMap(([action, token]) => this.apiService.post(`${environment.AppID}/ChangePhoneSendCode`,
        { phoneNumber: action.payload.newPhone, captchaResponce: action.payload.captchaResponce, accessToken: token })
        .pipe(
          map(resp => {
            if (resp.success) {
              return new ChangePhoneSuccess({ state: ChangingStateTypes.Confirm });
            } else {
              return new ChangePhoneError({ errMessage: resp.errorMessage, state: ChangingStateTypes.InvalidError });
            }
          }),
          catchError(errMessage => of(new ChangePhoneError({ errMessage, state: ChangingStateTypes.Error })))
        )
      )
    );

  @Effect()
  confirmPhoneChange$ = this.actions$
    .pipe(
      ofType<ConfirmPhoneChange>(UserActionTypes.ConfirmPhoneChange),
      withLatestFrom(this.store.select(selectAuthenticatedToken)),
      mergeMap(([action, token]) => this.apiService.post(`${environment.AppID}/ChangePhoneConfirm`,
        { phone_number: action.payload.newPhone, accessToken: token, confirmation_code: action.payload.code },
      )
        .pipe(
          map(resp => {
            if (resp.success) {
              return new ChangePhoneSuccess({ state: ChangingStateTypes.Success, newPhone: action.payload.newPhone });
            } else {
              return new ChangePhoneError({
                errMessage: { message: 'Wrong confirmation code' },
                state: ChangingStateTypes.Error
              });
            }
          }),
          catchError(errMessage => of(new ChangePhoneError({ errMessage, state: ChangingStateTypes.Error })))
        )
      )
    );

  @Effect()
  requestVerificationCOdeAgain$ = this.actions$
    .pipe(
      ofType<RequestVerificationCodeAgain>(UserActionTypes.RequestVerificationCodeAgain),
      switchMap((action) => this.secHttp.post('/client/sendphonecodeagain', {}, this.authService.getAuthHeaders())
        .pipe(
          catchError(errMessage => of(new ChangePhoneError({ errMessage, state: ChangingStateTypes.Error })))
        )
      )
    );

  @Effect()
  toggleMarketingSubscription$ = this.actions$
    .pipe(
      ofType<ToggleMarketingSubscription>(UserActionTypes.ToggleMarketingSubscription),
      withLatestFrom(this.store.select(selectAuthenticatedToken)),
      switchMap(([action, token]) => {
        return this.apiService.post(`${environment.AppID}/UpdateClientParams`, {
          receiveNews: action.payload.turnEmailOn,
          marketingAccepted: action.payload.turnSmsOn,
          marketingAcceptedPartner: action.payload.turnPartnerOn,
          accessToken: token,
        })
          .pipe(
            switchMap(resp => {
              const { turnEmailOn, turnSmsOn, turnPartnerOn } = action.payload;
              let neededText = '';
              let neededPopup = null;

              if (turnEmailOn) {
                neededPopup = AccountPopupTypes.AcceptanceInfo;
                neededText = action.payload.turnEmailOn ?
                  'Wyrażam zgodę na otrzymywanie informacji handlowych drogą elektroniczną' :
                  'Wycofuję zgodę na otrzymywanie informacji handlowych drogą elektroniczną';
              } else {
                neededText = action.payload.turnSmsOn ?
                  'Wyrażam zgodę na przetwarzanie moich danych w celach marketingowych' :
                  'Wycofuję zgodę na przetwarzanie moich danych w celach marketingowych';
              }
              const action1 = new MarketingSubscriptionSuccess({ turnSmsOn, turnEmailOn, turnPartnerOn });
              const action2 = new SetAcceptanceText(neededText);

              return of(action1, action2);
            }),
            catchError(errMessage => of(new MarketingSubscriptionError({ errMessage })))
          );
      }
      )
    );

  @Effect()
  changeUserPassword$ = this.actions$
    .pipe(
      ofType<ChangeUserPassword>(UserActionTypes.ChangePassword),
      withLatestFrom(this.store.select(selectAuthenticatedToken)),
      switchMap(([action, token]) => {
        return this.apiService.post(`${environment.AppID}/ChangePassword`, {
          oldpassword: action.payload.oldPass,
          newPassword: action.payload.newPass,
          accessToken: token,
          captchaResponce: action.payload.captchaResponce
        })
          .pipe(
            map(resp => {
              if (resp.success) {
                return new SetChangingState(ChangingStateTypes.Success);
              } else {
                // tslint:disable-next-line:max-line-length
                return new SetChangingState(ChangingStateTypes.Error);
              }
            }),
            catchError(errMessage => of(new SetChangingState(ChangingStateTypes.Error)))
          );
        /*
          .pipe(
            map(resp => new SetChangingState(ChangingStateTypes.Success)),
          );*/
      }
      )
    );


  @Effect()
  loadLoanHistory$ = this.actions$
    .pipe(
      ofType<LoadLoanHistory>(UserActionTypes.LoadLoanHistory),
      withLatestFrom(this.store.select(selectAuthenticatedToken)),
      switchMap(([action, token]) => token ? this.apiService.post(`${environment.AppID}/GetLoanList`, {
        accessToken: token
      })
        .pipe(
          switchMap(resp => {
            if (resp.success) {
              const action1 = new SetLoanHistory({ loanHistory: resp.loans });
              const action2 = new SetLoanHistoryLoadedStatus(true);
              return of(action1, action2);
            } else {
              const action1 = new OpenModal(ModalTypes.CommonErrorModal);
              const action2 = new SetCommonErrorMessage({ errMessage: 'Loan history is empty' });
              return of(action1, action2);
            }
          })
        )
        : of(new SetLoanHistoryLoadedStatus(false))
      )
    );

  @Effect()
  affiliateCompleteRegistration$ = this.actions$
    .pipe(
      ofType<AffiliateCompleteRegistration>(UserActionTypes.AffiliateCompleteRegistration),
      withLatestFrom(this.store.select(selectAuthenticatedToken)),
      switchMap(([action, token]) => {

        return this.apiService.post(`${environment.AppID}/AffApiCompleteRegistration`, {
          password: action.payload.completeRegData.password,
          conditionsData: action.payload.completeRegData.conditionsData,
          accessToken: token,
          loanData: action.payload.completeRegData.loanData,
          customerData: action.payload.completeRegData.customerData
        })
          .pipe(
            switchMap(resp => {

              this.spinner.hide();


              if (!resp.success) {
                return this.handleAuthOrRegError(resp.validationErrors);
              }

              if (resp.success) {
                const action0 = new LoadUserData({ token: this.authService.getAuthToken() });
                const action1 = new LoadLoanCurrentInfo();
                const action2 = new AffiliateCompleteRegistrationSuccess();
                return of(action0, action1, action2);
              } else {
                const action1 = new OpenModal(ModalTypes.CommonErrorModal);
                const action2 = new SetCommonErrorMessage({ errMessage: 'Failed to complete registration' });
                return of(action1, action2);
              }
            })
          );
      })
    );



  @Effect()
  loanSmsVerification$ = this.actions$
    .pipe(
      ofType<LoanSmsVerification>(UserActionTypes.LoanSmsVerification),
      withLatestFrom(this.store.select(selectAuthenticatedToken)),
      switchMap(([action, token]) => {
        const { verificationCode } = action.payload;

        return this.apiService.post(`${environment.AppID}/ConfirmLoan`, {
          confirmationCode: verificationCode,
          accessToken: token,
          alfuser_prefs: getElementById('alfuser_prefs'),
          alfuser_prefs2: getElementById('alfuser_prefs2'),
          headers: getHeaders(),
          cookies: getCookies(),
          ip: getIp()
        })
          .pipe(
            switchMap(resp => {

              globalVariables.AllowConfirmLoan = true;

              if (resp.success) {

                if (navigator.cookieEnabled) {
                  window.localStorage.removeItem('redirected');
                } else {
                  globalVariables.redirected = null;
                }

                const action1 = new LoadUserData({ token: token  });
                const action2 = new SetLoanSmsVerificationStatus({ status: true });
                const action3 = new LoanSmsVerificationSuccess();
                const action4 = new SetLoanVerificationFormSubmittedStatus(true);
                const action5 = new SetLoanCurrentInfo({ loanCurrentInfo: { loanConfirmed: true } });
                const action6 = new LoadLoanCurrentInfo();
                return of(action1, action3, action4,action5, action2, action6);
              } else {
                const action0 = new SetLoanVerificationFormSubmittedStatus(true);
                const action1 = new LoanSmsVerificationError({ errMessage: 'Invalid code' });
                const action2 = new LoanRequestError({ errMessage: 'Loan not confirmed' });
                return of(action0, action1, action2);
              }
            }),
            catchError(errMessage => {
              const action0 = new SetLoanVerificationFormSubmittedStatus(true);
              const action1 = new LoanSmsVerificationError({ errMessage: 'Invalid code' });
              const action2 = new LoanRequestError({ errMessage: 'Loan not confirmed' });
              return of(action0, action1, action2);
            })
          );
      })
    );

  @Effect()
  requestSmsCode$ = this.actions$
    .pipe(
      ofType<RequestSmsCode>(UserActionTypes.RequestSmsCode),
      mergeMap(action => {
        const { clientId, loanRequestId } = action.payload;
        return this.secHttp.post('/loanRequest/send-verification-code', {
          clientId,
          loanRequestId,
        }, this.authService.getAuthHeaders())
          .pipe(
            map(resp => new RequestSmsCodeSuccess()),
            catchError(errMessage => of(new RequestSmsError({ errMessage: errMessage.error })))
          );
      })
    );

  @Effect()
  loadUserNotifications$ = this.actions$
    .pipe(
      ofType<LoadUserNotifications>(UserActionTypes.LoadUserNotifications),
      withLatestFrom(this.store.select(selectAuthenticatedToken)),
      mergeMap(([action, token]) => {
        // return this.apiService.post('/loanRequest/send-verification-code', {
        //   accessToken: token
        // })
        return of([
          {
            id: 1,
            title: 'Lorem ipsum dolor sit amet',
            time: new Date(),
            text: 'lorem ipsum',
            viewed: true,
            documents: []
          },
          {
            id: 2, title: 'Lorem ipsum dolor sit amet',
            time: new Date(),
            text: 'Cras sollicitudin tincidunt laoreet. Proin sollicitudin ultricies sagittis.',
            viewed: false, documents: [
              { title: 'Proin sollicitudin ultricies sagittis.pdf', id: 2 },
              { title: 'Vestibulum gravida, lacus sit amet.pdf', id: 3 }
            ]
          }
        ])
          .pipe(
            map(resp => new SetUserNotifications({ notifications: resp })),
            // catchError(errMessage => of(new RequestSmsError({errMessage: errMessage.error})))
          );
      })
    );

  @Effect()
  callConfirmLoanNotification$ = this.actions$
    .pipe(
      ofType<CallConfirmLoanNotification>(UserActionTypes.CallConfirmLoanNotification),
      withLatestFrom(this.store.select(selectAuthenticatedToken)),
      mergeMap(([action, token]) => this.apiService.post(`${environment.AppID}/ConfirmLoanNotification`, {
        accessToken: token
      })
        .pipe(
          switchMap((resp) => {

            globalVariables.AllowConfirmLoan = true;

            if (resp.success) {
              const action0 = new SetCommonSuccessMessage({ successMessage: 'SMS z kodem został do Ciebie wysłany.' });
              const action1 = new OpenModal(ModalTypes.CommonSuccessModal);
              const action2 = new SetLoanConfirmationSmsRequestedStatus(true);
              return of(action0, action1, action2);
            } else {
              return of(
                new RequestSmsError({ errMessage: 'Sms could not be sent' }),
                new SetLoanConfirmationSmsRequestedStatus(false)
              );
            }
          }),
        )
      )
    );

  private handleAuthOrRegError(errors: any) {
    return of(new SetFormFieldErrors(errors));
  }

  constructor(private actions$: Actions,
    private authService: AuthService,
    private apiService: ApiService,
    private secHttp: SecureHttpClientService,
    private dataService: DataService,
    private store: Store<AppState>,
    private spinner: NgxSpinnerService,
    private router: Router) {
  }

}
