import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-marketing-agreement-content',
  templateUrl: './marketing-agreement-content.component.html',
  styleUrls: ['./marketing-agreement-content.component.css']
})
export class MarketingAgreementContentComponent implements OnInit {

  @Input()
  public title: String;

  constructor() { }

  ngOnInit() {
  }

}
