import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {
  InfoActionTypes,
  LoadContacts,
  LoadCurrentDate,
  LoadDocs,
  LoadInfoError,
  LoadMainPageInfo,
  LoadRefBanks,
  LoadRefJobs, SetCalculatorLoadStatus,
  SetCalculatorTerm,
  SetContacts,
  SetCurrentDate,
  SetDocs,
  SetMainPageInfo,
  SetRefBanks,
  SetRefJobs,
  LoadConditions,
  SetConditions, LoadRefBanksSuccess
} from '../actions';
import {forkJoin, of} from 'rxjs';
import {ApiService, AuthService, DataService} from '../../services';
import {catchError, map, mergeMap, switchMap, take, withLatestFrom} from 'rxjs/operators';
import {AppState} from '../state';
import {Store} from '@ngrx/store';
import {selectAuthenticatedToken, selectBanks} from '../selectors';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { globalVariables } from 'src/environments/globalVariables';
import { environment } from 'src/environments/environment';

@Injectable()
export class InfoEffects {

  // @ts-ignore
  @Effect()
  loadContacts$ = this.actions$
    .pipe(
      ofType<LoadContacts>(InfoActionTypes.LoadContacts),
      switchMap(
        (action) => this.dataService.getData('/admin/getcontacts')
          .pipe(
            // @ts-ignore
            map((contactsData: any) => new SetContacts({contacts: contactsData[0]})),
            catchError((errMessage) => of(new LoadInfoError({loadInfoError: errMessage}))),
          )
      )
    );

  @Effect()
  loadMainPageInfo$ = this.actions$
    .pipe(
      ofType<LoadMainPageInfo>(InfoActionTypes.LoadMainPageInfo),
      map(action => action),
      switchMap(() => forkJoin([
       // this.dataService.getData('/api/getcomments'),
        // next 2 api calls actually dont used in app. So I commented them
        // this.dataService.getData('/api/getimage'),
        // this.dataService.getData('/api/getnews'),
      ])),
      map((data: any) => {
        // const [{image}, {comments}, {news}] = data;
        const [{comments}] = data;
        return new SetMainPageInfo({
          mainPageData: {
        //    comments: data[0],
            // image,
            // news
          }
        });
      }),
    );

  @Effect()
  loadRefBanks$ = this.actions$
    .pipe(
      ofType<LoadRefBanks>(InfoActionTypes.LoadRefBanks),
      withLatestFrom(this.store.select(selectAuthenticatedToken)),
      switchMap(([action, token]) => this.apiService.post(`/${environment.AppID}/GetBanks`,
        token ? {accessToken: token} : null).pipe(
        withLatestFrom(this.store.select(selectBanks)),
        switchMap(([resp, banksList]) => {
          if (resp.success && !_.isEqual(banksList, resp.banks)) {
            return of(new SetRefBanks({banks: resp.banks}));
          }
          return of(new LoadRefBanksSuccess());
        }),
        catchError((errMessage) => of(new LoadInfoError({loadInfoError: errMessage}))),
        )
      )
    );

  @Effect()
  loadRefJobs$ = this.actions$
    .pipe(
      ofType<LoadRefJobs>(InfoActionTypes.LoadRefJobs),
      mergeMap(action => forkJoin([
          this.apiService.get(`${environment.AppID}/GetEmployerBranch`),
          this.apiService.get(`${environment.AppID}/GetIncomeRange`),
          this.apiService.get(`${environment.AppID}/GetIncomeSource`)
        ])
          .pipe(
            take(1),
            map((resp: any) => {
              if (resp && resp.length === 3) {
                const {income_sources, income_ranges, employer_branchs} = Object.assign({}, ...resp);
                return new SetRefJobs({
                  ranges: income_ranges,
                  occupations: employer_branchs,
                  sources: income_sources
                });
              }
            }),
            catchError((errMessage) => of(new LoadInfoError({loadInfoError: errMessage}))),
          )
      )
    );

  @Effect()
  loadCurrentDate$ = this.actions$
    .pipe(
      ofType<LoadCurrentDate>(InfoActionTypes.LoadCurrentDate),
      mergeMap(action => this.apiService.get('date/now')
        .pipe(
          map(date => new SetCurrentDate(date)),
          catchError((errMessage) => of(new LoadInfoError({loadInfoError: errMessage}))),
        )
      )
    );

  @Effect()
  loadConditions$ = this.actions$
    .pipe(
      ofType<LoadConditions>(InfoActionTypes.LoadConditions),
      withLatestFrom(this.store.select(selectAuthenticatedToken)),
      mergeMap(([action, token]) => {
          return this.apiService.post(`/${environment.AppID}/GetCalculatorInformation`,
            token ? {accessToken: token} : null)
            .pipe(
              switchMap((resp: any) => {
                if (resp.success) {
                  const action1 = new SetCalculatorTerm(resp.term || resp.minTerm, resp.maxTerm,
                    resp.minTerm, resp.amountThreshold, resp.maxAmount, resp.minAmount);
                  const action2 = new SetConditions({conditions: resp.longLoanCalculator.amounts});
                  const action3 = new SetCalculatorLoadStatus(token ? true : false);
                  if (navigator.cookieEnabled ? localStorage.getItem('Logout') : globalVariables.Logout) {
                    if (navigator.cookieEnabled) {
                      localStorage.removeItem('Logout');
                    } else {
                      globalVariables.Logout = null;
                    }

                    this.router.navigate(['/']);
                  }
                  return of(action1, action2, action3);
                } else {
                  return of(new LoadInfoError({loadInfoError: 'No calculator data'}));
                }
              }),
              catchError((errMessage) => of(new LoadInfoError({loadInfoError: errMessage}))),
            );
        }
      )
    );

  @Effect()
  loadDocs$ = this.actions$
    .pipe(
      ofType<LoadDocs>(InfoActionTypes.LoadDocs),
      withLatestFrom(this.store.select(selectAuthenticatedToken)),
      mergeMap(([action, token]) => {
          return this.apiService.post(`${environment.AppID}/GetDocument`, {
              documentId: action.loanNumber,
              accessToken: token,
            }
          )
            .pipe(
              map(docs => new SetDocs({loanNumber: action.loanNumber, docs}))
            );
        }
      )
    );

  constructor(private actions$: Actions,
              private dataService: DataService,
              private apiService: ApiService,
              private authService: AuthService,
              private router: Router,
              private store: Store<AppState>) {
  }
}
