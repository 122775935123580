import {Injectable} from '@angular/core';
import {DataService} from './data.service';
import {Observable, of} from 'rxjs';
import {NavigationItem} from '../../models';

@Injectable()
export class NavigationService {

  constructor(private dataService: DataService) {
  }

  public getMainNavigation(): Observable<NavigationItem[]> {
    return of([
      {
        id: 0,
        title: 'Pożyczka Ratao',
        alias: 'pozyczka-ratao'
      },
      {
        id: 0,
        title: 'Poznaj nas',
        alias: 'poznaj-nas'
      },
      {
        id: 0,
        title: 'Pytania i odpowiedzi',
        alias: 'pytania-i-odpowiedzi'
      },
      {
        id: 0,
        title: 'Kontakt',
        alias: 'kontakt'
      }
    ]);
  }

  public getFooterNavigation(): Observable<NavigationItem[]> {
    return of([
      {id: 0, title: 'Ramowa Umowa Pożyczki', alias: '/warunki-umowy'},
      {id: 1, title: 'Regulamin Serwisu Klienta', alias: '/regulamin'},
      //{id: 2, title: 'Regulamin Promocji', alias: '/regulamin-promocji-pierwsza-pozyczka-promocyjna'},
      //{id: 2, title: 'Regulamin Promocji', alias: '/regulamin-promocji'},
      {id: 3, title: 'Polityka Prywatności', alias: '/polityka-prywatnosci'}
    ]);
    // return this.dataService.getData('/api/footer-menu');
  }

  public getSecondaryFooterNavigation(): Observable<any> {
    return this.dataService.getData('/api/footer-menu2');
  }

  public getAccountNavigation(): Observable<NavigationItem[]> {
    return this.dataService.getData('/api/account-menu');
  }
}
