import {Component, OnInit} from '@angular/core';
import {AppState} from './store/state';
import {select, Store} from '@ngrx/store';
import {LoadAllNavigations, LoadConditions, LoadContacts, LoadCurrentDate, LogOut, SetAuthenticationStatus} from './store/actions';
import {Observable} from 'rxjs';
import {NavigationItem} from '../models';
import {
  selectCurrentModalType,
  selectFooterNavigation,
  selectFooterSecondaryNavigation,
  selectNavigation,
  selectUserNavigation
} from './store/selectors';
import {ModalTypes} from './enums';
import {AuthService, ApiService, AffiliateService} from './services';
import {NavigationEnd, Router} from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { globalVariables } from 'src/environments/globalVariables';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public navItems$: Observable<NavigationItem[]>;
  public userNavItems$: Observable<NavigationItem[]>;
  public footerNavItems$: Observable<NavigationItem[]>;
  public footerSecondaryNavItems$: Observable<NavigationItem[]>;
  public modalTypes = ModalTypes;
  public selectedModal$: Observable<number>;

  constructor(private store: Store<AppState>,
              private authService: AuthService,
              private router: Router,
              private cookieService: CookieService,
              private apiService: ApiService,
              private affService: AffiliateService) {

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.authService.checkAuthState();
    this.affService.subscribe();
  }

  ngOnInit(): void {

    if (this.authService.getAuthToken()) {
      if (this.authService.getExpiredToken() &&
      (new Date()).getTime() < (Number(this.authService.getExpiredToken()))) {
        this.authService.setExpiredToken((String)((new Date()).getTime() + environment.tokenExpireTime));
      } else {
        this.store.dispatch(new LogOut());
        this.store.dispatch(new SetAuthenticationStatus({isAuthenticated: false, token: null}));
      }
    }

    this.store.dispatch(new LoadAllNavigations());
    this.store.dispatch(new LoadContacts());
    this.store.dispatch(new LoadCurrentDate());
    if (!this.authService.getAuthToken()) {
      this.store.dispatch(new LoadConditions());
    }

    this.navItems$ = this.store.pipe(select(selectNavigation));
    this.userNavItems$ = this.store.pipe(select(selectUserNavigation));
    this.selectedModal$ = this.store.pipe(select(selectCurrentModalType));
    this.footerNavItems$ = this.store.pipe(select(selectFooterNavigation));
    this.footerSecondaryNavItems$ = this.store.pipe(select(selectFooterSecondaryNavigation));

    if (document.referrer != null && document.referrer.length > 0 && document.referrer.indexOf(location.protocol + '//' + location.host) < 0) {
      if (navigator.cookieEnabled) {
        this.cookieService.set('FinSasClientReferrer', document.referrer);
      } else {
        globalVariables.FinSasClientReferrer = document.referrer;
      }
    }

    if ((document.referrer == null || document.referrer.indexOf(location.protocol + '//' + location.host) < 0) && window.location.search.length > 0) {
      this.apiService.post(`/${environment.AppID}/GetAffiliate`, { url: window.location.href })
      .subscribe(
        (val) => {
            if (val && val.success === true && val.networkID !== 0) {
              if (navigator.cookieEnabled) {
                this.cookieService.set('FinSasAff', window.location.href, val.lifetime > 0 ?
                  val.lifetime / 86400 : 30);
              } else {
                globalVariables.FinSasAff = window.location.href;
              }
            }
        });
  }
}
}
