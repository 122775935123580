import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-client-agreement-content',
  templateUrl: './client-agreement-content.component.html',
  styleUrls: ['./client-agreement-content.component.css']
})
export class ClientAgreementContentComponent implements OnInit {

  @Input()
  public title: String;


  constructor() { }

  ngOnInit() {
  }

}
