import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {ModalComponent} from './components/modal/modal.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ModalBodyComponent} from './components/modal/modal-body/modal-body.component';
import {ModalFooterComponent} from './components/modal/modal-footer/modal-footer.component';
import {LoginModalComponent} from './components/modals/login-modal/login-modal.component';
import {ForgetPasswordModalComponent} from './components/modals/forget-password-modal/forget-password-modal.component';
import {CalculatorComponent} from './components/calculator/calculator.component';
import {SidebarComponent} from './components/sidebar/sidebar.component';
import {Ng5SliderModule} from 'ng5-slider';
import {ValidationComponent} from './components/validation/validation.component';
import {AddValidationDirective} from './directives/add-validation.directive';
import {CompareDirective} from './directives/compare.directive';
import {EmailDirective} from './directives/email.directive';
import {UppercaseDirective} from './directives/uppercase.directive';
import {ManualValidationComponent} from './components/manual-validation/manual-validation.component';
import {NgxMaskModule} from 'ngx-mask';
import {DocumentationModalComponent} from './components/modals/documentation-modal/documentation-modal.component';
import {FileListComponent} from './components/filelist/file-list.component';
import {BanksSelectorComponent} from './components/banks-selector/banks-selector.component';
import {HelpPanelComponent} from './components/help-panel/help-panel.component';
import {CommonErrorModalComponent} from './components/modals/common-error-modal/common-error-modal.component';
import {CommonSuccessModalComponent} from './components/modals/common-success-modal/common-success-modal.component';
import {RegErrorModalComponent} from './components/modals/reg-error-modal/reg-error-modal.component';
import {FormularModalComponent} from './components/modals/formular-modal/formular-modal.component';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import { DiscountRegulationsModalComponent } from './components/modals/discount-regulations-modal/discount-regulations-modal.component';
import { FreeLoanReadMoreModalComponent } from './components/modals/free-loan-read-more-modal/free-loan-read-more.component';
import { LoanCostsModalComponent } from './components/modals/loan-сosts-modal/loan-сosts-modal.component';
import { KotmaticModalComponent } from './components/modals/kotmatic-modal/kotmatic-modal.component';
import { ContentModalComponent } from './components/modals/content-modal/content-modal.component';
// tslint:disable-next-line:max-line-length
import { TermsAndConditionsContentComponent } from '../static-pages/pages-content/terms-and-conditions-content/terms-and-conditions-content.component';
import { RegulationsPageContentComponent } from '../static-pages/pages-content/regulations-page-content/regulations-page-content.component';
// tslint:disable-next-line:max-line-length
import { InformationClauseContentComponent } from '../static-pages/pages-content/information-clause-content/information-clause-content.component';
import { PrivacyPolicyContentComponent } from '../static-pages/pages-content/privacy-policy-content/privacy-policy-content.component';
// tslint:disable-next-line:max-line-length
import { ElectronicAgreementContentComponent } from '../static-pages/pages-content/electronic-agreement-content/electronic-agreement-content.component';
// tslint:disable-next-line:max-line-length
import { MarketingAgreementContentComponent } from '../static-pages/pages-content/marketing-agreement-content/marketing-agreement-content.component';
import { StatementPepContentComponent } from '../static-pages/pages-content/statement-pep-content/statement-pep-content.component';
import { ClientAgreementContentComponent } from '../static-pages/pages-content/client-agreement-content/client-agreement-content.component';
import { AuthorityContentComponent } from '../static-pages/pages-content/authority-content/authority-content.component';
import { AgreementBigContentComponent } from '../static-pages/pages-content/agreement-big-content/agreement-big-content.component';
import { ModalContentComponent } from './components/modal-content/modal-content.component';
import { Bts2021PageContentComponent } from '../static-pages/pages-content/bts2021-page-content/bts2021-page-content.component';
import { RegulaminPromocjiPozyczkaPromocyjnaPageContentComponent } from '../static-pages/pages-content/regulamin-promocji-pozyczka-promocyjna-content]/regulamin-promocji-pozyczka-promocyjna-content.component';
import { NoOverdueDeptsContentComponent } from '../static-pages/pages-content/no-overdue-depts-content/no-overdue-depts-content.component';
import { UserModalComponent } from './components/modal/user-modal.component';
import { FooterBlockComponent } from '../main-page/components/app-footer-block/app-footer-block.component';
import { ReviewsSliderComponent } from '../main-page/components/reviews-slider/reviews-slider.component';
import {SWIPER_CONFIG, SwiperConfigInterface, SwiperModule} from 'ngx-swiper-wrapper';
import { ReCaptchaModule } from 'angular2-recaptcha';
import { MarketingPartnerAgreementContentComponent } from '../static-pages/pages-content/marketing-partner-agreement-content/marketing-partner-agreement-content.component';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
};

@NgModule({
  entryComponents: [
    ValidationComponent,
    ManualValidationComponent,
  ],
  declarations: [
    ModalComponent,
    UserModalComponent,
    ModalContentComponent,
    ModalBodyComponent,
    ModalFooterComponent,
    LoginModalComponent,
    ForgetPasswordModalComponent,
    DocumentationModalComponent,
    HelpPanelComponent,
    FooterBlockComponent,
    CalculatorComponent,
    SidebarComponent,
    ValidationComponent,
    ManualValidationComponent,
    FileListComponent,
    BanksSelectorComponent,
    AddValidationDirective,
    CompareDirective,
    EmailDirective,
    UppercaseDirective,
    CommonErrorModalComponent,
    CommonSuccessModalComponent,
    RegErrorModalComponent,
    FormularModalComponent,
    FreeLoanReadMoreModalComponent,
    KotmaticModalComponent,
    ContentModalComponent,
    TermsAndConditionsContentComponent,
    ClientAgreementContentComponent,
    PrivacyPolicyContentComponent,
    RegulationsPageContentComponent,
    RegulaminPromocjiPozyczkaPromocyjnaPageContentComponent,
    InformationClauseContentComponent,
    ElectronicAgreementContentComponent,
    AuthorityContentComponent,
    Bts2021PageContentComponent,
    StatementPepContentComponent,
    NoOverdueDeptsContentComponent,
    MarketingAgreementContentComponent,
    MarketingPartnerAgreementContentComponent,
    LoanCostsModalComponent,
    DiscountRegulationsModalComponent,
    AgreementBigContentComponent,
    ReviewsSliderComponent,
  ],
  imports: [
    Ng5SliderModule,
    SwiperModule,
    FormsModule,
    ReactiveFormsModule,
    ReCaptchaModule,
    CommonModule,
    RouterModule,
    NgxMaskModule.forRoot(),
    NgxExtendedPdfViewerModule,

  ],
  exports: [
    ModalComponent,
    UserModalComponent,
    ModalContentComponent,
    ModalBodyComponent,
    ModalFooterComponent,
    LoginModalComponent,
    ForgetPasswordModalComponent,
    CalculatorComponent,
    FooterBlockComponent,
    ReviewsSliderComponent,
    ValidationComponent,
    ManualValidationComponent,
    SidebarComponent,
    FileListComponent,
    BanksSelectorComponent,
    HelpPanelComponent,
    AddValidationDirective,
    CompareDirective,
    EmailDirective,
    UppercaseDirective,
    DocumentationModalComponent,
    CommonErrorModalComponent,
    CommonSuccessModalComponent,
    RegErrorModalComponent,
    FormularModalComponent,
    FreeLoanReadMoreModalComponent,
    KotmaticModalComponent,
    ContentModalComponent,
    TermsAndConditionsContentComponent,
    ClientAgreementContentComponent,
    PrivacyPolicyContentComponent,
    RegulationsPageContentComponent,
    RegulaminPromocjiPozyczkaPromocyjnaPageContentComponent,
    InformationClauseContentComponent,
    ElectronicAgreementContentComponent,
    AuthorityContentComponent,
    Bts2021PageContentComponent,
    StatementPepContentComponent,
    NoOverdueDeptsContentComponent,
    MarketingAgreementContentComponent,
    MarketingPartnerAgreementContentComponent,
    LoanCostsModalComponent,
    DiscountRegulationsModalComponent,
    AgreementBigContentComponent,
  ],providers: [
    { provide: SWIPER_CONFIG, useValue: DEFAULT_SWIPER_CONFIG },
  ]
})
export class SharedModule {
}
