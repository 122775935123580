import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms-and-conditions-content',
  templateUrl: './terms-and-conditions-content.component.html',
  styleUrls: ['./terms-and-conditions-content.component.css']
})
export class TermsAndConditionsContentComponent implements OnInit {

  @Input()
  public title: String;

  constructor() { }

  ngOnInit() {
  }

}
