import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-statement-pep-content',
  templateUrl: './statement-pep-content.component.html',
  styleUrls: ['./statement-pep-content.component.css']
})

export class StatementPepContentComponent implements OnInit {
  
  @Input()
  public title: String;

  constructor() { }

  ngOnInit() {
  }

}
