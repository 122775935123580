import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-bts2021-content',
  templateUrl: './bts2021-page-content.component.html',
  styleUrls: ['./bts2021-page-content.component.css']
})
export class Bts2021PageContentComponent implements OnInit {

  @Input()
  public title: String;

  constructor() { }

  ngOnInit() {
  }

}
