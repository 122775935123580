import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {
  LoadFooterNavigation, LoadFooterSecondaryNavigation,
  LoadNavigation,
  LoadUserNavigation,
  NavigationActionTypes,
  SetNavigationError, SetFooterNavigation, SetFooterSecondaryNavigation,
  SetNavigation,
  SetUserNavigation
} from '../actions';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {NavigationItem} from '../../../models';
import {of} from 'rxjs';
import {NavigationService} from '../../services/navigationService';

@Injectable()
export class NavigationEffects {

  @Effect()
  loadAllNavigations$ = this.actions$
    .pipe(
      ofType(NavigationActionTypes.LoadAllNavigations),
      switchMap((action) => [
          new LoadNavigation(),
          new LoadFooterNavigation(),
        ]
      )
    );

  @Effect()
  loadNavigation$ = this.actions$
    .pipe(
      ofType<LoadNavigation>(NavigationActionTypes.LoadNavigation),
      mergeMap(
        (action) => this.navService.getMainNavigation()
          .pipe(
            map((navigation: NavigationItem[]) => new SetNavigation({navigationData: navigation})),
            catchError((errMessage) => of(new SetNavigationError({error: errMessage}))),
          )
      )
    );

  @Effect()
  loadUserNavigation$ = this.actions$
    .pipe(
      ofType<LoadUserNavigation>(NavigationActionTypes.LoadUserNavigation),
      mergeMap(
        (action) => this.navService.getAccountNavigation()
          .pipe(
            map((navigation: NavigationItem[]) => new SetUserNavigation({userNavigationData: navigation})),
            catchError((errMessage) => of(new SetNavigationError({error: errMessage}))),
          )
      )
    );

  @Effect()
  loadFooterNavigation$ = this.actions$
    .pipe(
      ofType<LoadFooterNavigation>(NavigationActionTypes.LoadFooterNavigation),
      mergeMap(
        (action) => this.navService.getFooterNavigation()
          .pipe(
            map((navigation: NavigationItem[]) => new SetFooterNavigation({footerNavigationData: navigation})),
            catchError((errMessage) => of(new SetNavigationError({error: errMessage}))),
          )
      )
    );

  @Effect()
  loadFooterSecondaryNavigation$ = this.actions$
    .pipe(
      ofType<LoadFooterSecondaryNavigation>(NavigationActionTypes.LoadSecondaryFooterNavigation),
      mergeMap(
        (action) => this.navService.getSecondaryFooterNavigation()
          .pipe(
            map((navigation: NavigationItem[]) => new SetFooterSecondaryNavigation({footerSecondaryNavigationData: navigation})),
            catchError((errMessage) => of(new SetNavigationError({error: errMessage}))),
          )
      )
    );

  constructor(private actions$: Actions,
              private navService: NavigationService) {
  }

}
