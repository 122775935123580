import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {selectCommonSuccessMessage} from '../../../../../store/selectors/info.selectors';
import {AppState} from '../../../../../store/state';
import {Store} from '@ngrx/store';
import {CloseModal, SetCommonSuccessMessage} from '../../../../../store/actions';

@Component({
  selector: 'app-common-success-modal',
  templateUrl: './common-success-modal.component.html',
  styleUrls: ['./common-success-modal.component.scss']
})
export class CommonSuccessModalComponent implements OnInit {
  public successText: Observable<string>;

  constructor(private store: Store<AppState>) { }

  ngOnInit() {
    this.successText = this.store.select(selectCommonSuccessMessage);
  }

  public close = () => {
    this.store.dispatch(new CloseModal());
    this.store.dispatch(new SetCommonSuccessMessage({successMessage: ''}));
  }

}
