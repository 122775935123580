import {AppState} from '../state';
import {createSelector} from '@ngrx/store';

export const selectContacts = (state: AppState) => state.info.contacts;

export const selectMainPageComments = (state: AppState) => state.info.mainPageData && state.info.mainPageData.comments;

export const selectBanks = (state: AppState) => state.info && state.info.banks;

export const selectRanges = (state: AppState) => state.info.ranges;

export const selectOccupations = (state: AppState) => state.info.occupations;

export const selectSources = (state: AppState) => state.info.sources;

export const selectDateTime = (state: AppState) => state.info.dateTime;

export const selectConditions = (state: AppState) => state.info.conditions;

export const selectCalculatorData = (state: AppState) => {
  if (state && state.info && state.info.calculator) {
    return {
      term: state.info.calculator.term,
      amount: state.info.calculator.amount,
      maxTerm: state.info.calculator.maxTerm,
      minTerm: state.info.calculator.minTerm,
      amountThreshold: state.info.calculator.amountThreshold,
      maxAmount: state.info.calculator.maxAmount,
      minAmount:  state.info.calculator.minAmount,
      total: state.info.calculator.total
    };
  }
};

export const selectCalculatorLoadStatus = (state: AppState) => state.info && state.info.calculatorLoaded;

export const selectExactConditions = createSelector(
  selectConditions,
  selectCalculatorData,
  (conditions, calculatorData) => {
    
    if (!conditions.length) {
      return;
    }
    const {amount, term} = calculatorData;
    for (const key of conditions) {
      for (const trm of key.term){
        if (amount === key.amount && trm.months === term) {
          return trm;
        }
      }     
    }
  }
);

export const selectExactBank = createSelector(
  selectBanks,
  (banks, props: {bankName: string}) => {
    if (!props.bankName) {
      return banks.filter(b => b.defaultBank === true)[0];
    }
    return banks.filter(b => b.name === props.bankName)[0];
  }
);

export const selectAcceptanceText = (state: AppState) => state.info.acceptanceText;

export const selectDocs = (state: AppState) => state.info.docs;

export const selectCertainDocs = createSelector(
  selectDocs,
  (docs, props: {loanNumber: any}) => {
    if (docs && props.loanNumber) {
      const neededItem = docs.filter(el => el && el.loanNumber.toString() === props.loanNumber.toString())[0];
      if (neededItem) {
        return neededItem.docs;
      } else {
        return [];
      }
    }
  }
);

export const selectCommonErrorMessage = (state: AppState) => state.info && state.info.commonErrorMessage;

export const selectCommonSuccessMessage = (state: AppState) => state.info && state.info.commonSuccessMessage;

export const selectFormularDoc = (state: AppState) => state.info && state.info.formular;

export const selectPageContent = (state: AppState) => state.info && state.info.pageContent;

export const parentPageContent = (state: AppState) => state.info && state.info.parentPage;


