import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-reviews-slider',
  templateUrl: './reviews-slider.component.html',
  styleUrls: ['./reviews-slider.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewsSliderComponent implements OnInit {

  
  public sliderConfig: any;
  public sliderIndex: number;

  constructor() {
   
  }

  public reviews = [
    {
      Text: `Wszystko było dokładnie tak, jak napisali. Wziąłem, oddałem, polecam!`,
      Name: 'Marek z Gliwic',
      Rating: 5,
    },
    {
      Text: `Wszystko było dokładnie tak, jak napisali. Wziąłem, oddałem, polecam!`,
      Name: 'Marek z Gliwic',
      Rating: 5,
    },
    {
      Text: `Wszystko było dokładnie tak, jak napisali. Wziąłem, oddałem, polecam!`,
      Name: 'Marek z Gliwic',
      Rating: 5,
    },
    {
      Text: `Wszystko było dokładnie tak, jak napisali. Wziąłem, oddałem, polecam!`,
      Name: 'Marek z Gliwic',
      Rating: 5,
    },
    {
      Text: `Wszystko było dokładnie tak, jak napisali. Wziąłem, oddałem, polecam!`,
      Name: 'Marek z Gliwic',
      Rating: 5,
    },
    {
      Text: `Wszystko było dokładnie tak, jak napisali. Wziąłem, oddałem, polecam!`,
      Name: 'Marek z Gliwic',
      Rating: 5,
    }
  ];
  
  ngOnInit() {
    this.sliderConfig = {
      direction: 'horizontal',
      navigation: true,
      slidesPerView: 1.2,
      loop: true,
    };
    this.sliderIndex = 0;
  }

  public setStars(starsCount: number) {
    const tempStars = [];
    for (let i = 0; i < starsCount; i++) {
      tempStars.push('active');
    }
    for (let i = 0; i < 5 - starsCount; i++) {
      tempStars.push('regular');
    }
    return tempStars;
  }

}
