import { ModalTypes } from 'src/app/enums';

interface NewsItem {
  Id: number;
  Text: string;
  Name: string;
  Age: number;
  City: string;
  Rating: number;
}

interface CalculatorData {
  amount?: number;
  term?: number;
  maxTerm?: number;
  minTerm?: number;
  commission?: number;
  amountThreshold?: number;
  maxAmount?: number;
  minAmount?: number;
  total?: number;
}

export interface MainPageData {
  image: any;
  comments: any[] | null;
  news: NewsItem[] | null;
}

export interface InfoState {
  contacts?: any;
  mainPageData?: MainPageData;
  conditions?: any[];
  banks?: any[];
  occupations?: any[];
  ranges?: any[];
  sources?: any[];
  infoError?: any;
  dateTime?: string;
  calculator?: CalculatorData;
  calculatorLoaded?: boolean;
  acceptanceText?: string;
  docs?: DocsModel[];
  commonErrorMessage?: string;
  commonSuccessMessage?: string;
  formular?: FormularDoc;
  pageContent?: string;
  parentPage?: ModalTypes;
}

export const initialInfoState = {
  contacts: null,
  infoError: null,
  mainPageData: null,
  occupations: [],
  banks: [],
  ranges: [],
  sources: [],
  conditions: [],
};

export interface DocsModel {
  loanNumber: any;
  docs: any[];
}

export interface FormularDoc {
  title: string;
  data: string;
  type: string;
  
}
