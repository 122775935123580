import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { SecureHttpClientService } from 'src/app/services/secure-http-client.service';
import { Options } from 'ng5-slider';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';
import { AppState } from '../../../../store/state';
import { Store } from '@ngrx/store';
import {
  OpenModal,
  SetCalculatorAmount,
  SetCalculatorCommission,
  SetCalculatorTerm,
  SetFormularDoc
} from '../../../../store/actions';
import { selectDateTime, selectExactConditions, selectUser } from '../../../../store/selectors';
import { BehaviorSubject } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { addDays, debounce } from '../../../../utils';
import { ModalTypes } from '../../../../enums';
import { selectCalculatorData, selectConditions } from '../../../../store/selectors/info.selectors';
import { FileService } from '../../../../services/file.service';
import { SetCalculatorTotal } from 'src/app/store/actions/info.actions';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CalculatorComponent implements OnInit {
  private initCalculatorGuard: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input()
  public transparentStyle: boolean;
  @Input()
  public showPersonalConditions: boolean;
  @Output()
  public takeLoanClicked: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  public userDiscount: number;
  public currency = 'zł';
  @Input()
  public amount = 0;
  @Input()
  public term = 0;
  public total = 0;
  public maxTerm = 0;
  public minTerm = 0;
  public commission = 0;
  private amountBorder = 0;
  private amountThreshold = 0;

  @Input()
  public hideButton: boolean = false;

  // @TODO not sure but I think force repeat is redundant
  @Input() isForceRepeat = false;
  @Input() loanApplyState = 'next';
  @Input() maxAmount: number;
  @Input() minAmount: number;
  get apiUrl() {
    return environment.apiUrl;
  }

  public exactConditions$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  public amountOptions: Options = {
    floor: 100,
    ceil: 5000,
    step: 50,
    showTicks: true,
    showSelectionBar: true,
    showTicksValues: true,
    stepsArray: []
  };
  public termOptions: Options = {
    floor: 3,
    ceil: 12,
    step: 3,
    showSelectionBar: true
  };
  public activeLeft = true;
  public activeRight: boolean;
  public isMainPage: boolean;
  public isRegPage: boolean;
  public isNewLoanPage: boolean;

  public months: number[];

  constructor(private app: AppComponent,
    private secHttp: SecureHttpClientService,
    private router: Router,
    private http: ApiService,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private fileSaver: FileService,
    private changeDetector: ChangeDetectorRef) {

      this.store.select(selectConditions).subscribe(data => {
        if (data !== null && data !== undefined && data.length > 0) {
         // data[0].term.length

         this.months = [];
         data[0].term.forEach(element => {
          this.months.push(element.months);
         });

        }
      });

    this.store.select(selectCalculatorData)
      .pipe(
        tap()
      )
      .subscribe(data => {
        if (data && data.term && data.maxTerm && data.minTerm) {
          this.amount = data.amount ? data.amount : data.amountThreshold;
          this.term = data.term;
          this.maxTerm = data.maxTerm;
          this.minTerm = data.minTerm;
          this.maxAmount = data.maxAmount;
          this.minAmount = data.minAmount;
          this.amountThreshold = data.amountThreshold;
          this.initCalculatorGuard.next(true);
          this.amountBorder = this.amountThreshold;
          this.total = data.total
        }
      });
  }

  ngOnInit() {
    if (this.router.url === '/'
      || this.router.url === '/pozyczka-ratao'
      || this.router.url === '/poznaj-nas'
      || this.router.url === '/pytania-i-odpowiedzi'
      || this.router.url === '/logowanie'
      || this.router.url === '/kontakt') {
      this.isMainPage = true;
    } else if (this.router.url.substring(0, 13) === '/registration') {
      this.isRegPage = true;
      if (this.amount > this.amountBorder) {
        this.amount = 2000;
        this.store.dispatch(new SetCalculatorAmount(this.amount));
      }
    } else if (this.router.url === '/profil/prosba-pozyczki') {
      this.isNewLoanPage = true;
    }

    if (this.loanApplyState === 'first') {
      this.amountOptions.ceil = 2000;
    }

    this.activeRight = this.loanApplyState !== 'first';
    this.activeLeft = this.loanApplyState === 'first';

    this.initCalculatorGuard.pipe(take(2)).subscribe(canInit => {
      if (canInit) {
        this.initCalculator();
      }
    });
  }

  public increaseAmountRange() {
    this.amount += this.amountOptions.step;
  }

  public decreaseAmountRange() {
    this.amount -= this.amountOptions.step;
  }

  public increaseTermRange() {
    if (this.term === this.maxTerm) {
      return;
    }
    this.term += this.termOptions.step;
  }

  public decreaseTermRange() {
    this.term -= this.termOptions.step;
  }

  public setTerm(term) {
    this.term = term;
    this.checkTerm();
  }

  public setMonthString(month: number) {
    return month > 3 ? 'miesięcy' : 'miesiące';
  }

  public togglePanels() {
    this.activeLeft = !this.activeLeft;
    this.activeRight = !this.activeRight;
    if (this.activeRight) {
      this.loanApplyState = 'next';
    } else {
      this.loanApplyState = 'first';
    }
  }

  public checkAmount() {
    if (this.amount > this.amountBorder) {
      this.activeRight = true;
      this.activeLeft = false;
      this.loanApplyState = 'next';
    } else {
      this.activeRight = false;
      this.activeLeft = true;
      this.loanApplyState = 'first';
    }
    if (this.amount > this.amountOptions.ceil) {
      this.amount = this.amountOptions.ceil;
    } else if (this.amount < this.amountOptions.floor) {
      this.amount = this.amountOptions.floor;
    }

    if (this.activeRight) {
      this.insertToDom();
    } else {
      this.removeFromDom();
    }
    debounce(this.store.dispatch(new SetCalculatorAmount(this.amount)), 150);
  }

  public checkTerm() {
    if (this.term > this.termOptions.ceil) {
      this.term = this.termOptions.ceil;
    } else if (this.term < this.termOptions.floor) {
      this.term = this.termOptions.floor;
    }
    if (this.maxTerm && this.minTerm) {
      this.termOptions.minLimit = this.minTerm;
      this.termOptions.maxLimit = this.maxTerm;
      if (this.maxTerm === this.minTerm) {
        this.term = this.minTerm;
      }
    }

    debounce(this.store.dispatch(new SetCalculatorTerm(this.term, this.maxTerm, this.minTerm)), 150);
  }

  public setPrimary() {
    this.isForceRepeat = false;
    if (this.amount > this.amountBorder) {
      this.amount = this.amountBorder;
    }
  }

  public setRepeat() {
    this.isForceRepeat = true;
  }

  public routeRegistration(e) {
    e.preventDefault();
    this.router.navigate(['/rejestracja']);
  }

  public openLoginModal() {
    this.store.dispatch(new OpenModal(ModalTypes.LoginModal));
  }

  public openLoanInfoFile(amount, term, type, event) {
    event.preventDefault();
    this.fileSaver.openFormular(amount, term).then(resp => {
      this.store.dispatch(new SetFormularDoc({ formularDoc: resp.agreement, parent: ModalTypes.None }));
      this.store.dispatch(new OpenModal(ModalTypes.Formular));
    });
  }

  public requireNewLoan(event) {

      this.takeLoanClicked.emit(event);
  }

  private initCalculator() {
    this.amountOptions = {
      ...this.amountOptions,
      ceil: this.maxAmount ? this.maxAmount : this.amountThreshold,
      floor: this.minAmount ? this.minAmount : 100
    };


    if (this.amountOptions.stepsArray.length === 0)
      for (let i = this.amountOptions.floor; i <= this.amountOptions.ceil; i += this.amountOptions.step) {
        if (i % 1000 === 0) {
          this.amountOptions.stepsArray.push({ value: i, legend: '' });
        } else {
          this.amountOptions.stepsArray.push({ value: i });
        }
      }

    this.checkAmount();
    this.checkTerm();

    this.store.dispatch(new SetCalculatorCommission(this.commission));

    this.store.select(
      selectDateTime
    ).subscribe(dateTime => {
      if (!dateTime) {
        return;
      }
      this.store.select(selectExactConditions)
        .pipe(
          tap(),
          map(neededConditionValues => {
            if (!neededConditionValues) {
              return;
            }

            const today = new Date();

            if (this.showPersonalConditions) {
              return this.calculatePersonalValues(neededConditionValues, today);
            } else {
              return this.calculateUsualValues(neededConditionValues, today);
            }
          })
        ).subscribe(data => {
          if (!data) {
            return;
          } else {
            this.exactConditions$.next(data);
          }
        });
    });
  }

  private calculateUsualValues(neededConditionValues, today): any[] {

    this.total = neededConditionValues.total.toFixed(2);
    this.store.dispatch(new SetCalculatorTotal(this.total));

    return [
      {
        firstPaymentDate: neededConditionValues.firstPaymentDate,
        monthlyPayment: neededConditionValues.monthlyPayment.toFixed(2),
        months: neededConditionValues.months,
        amount: this.amount,
        rrso: neededConditionValues.rrso.toFixed(2),
        total: neededConditionValues.total.toFixed(2),
        totalCosts: neededConditionValues.totalCosts.toFixed(2),
      }
    ];
  }

  private calculatePersonalValues(neededConditionValues, today): any[] {

    this.total = neededConditionValues.total.toFixed(2);
    this.store.dispatch(new SetCalculatorTotal(this.total));

    return [
      {
        firstPaymentDate: neededConditionValues.firstPaymentDate,
        monthlyPayment: neededConditionValues.monthlyPayment.toFixed(2),
        months: neededConditionValues.months,
        amount: this.amount,
        rrso: neededConditionValues.rrso.toFixed(2),
        total: neededConditionValues.total.toFixed(2),
        totalCosts: neededConditionValues.totalCosts.toFixed(2),
      }];
  }

  private insertToDom() {
    const neededSelector = document.querySelector('.ng5-slider-span.ng5-slider-pointer.ng5-slider-pointer-min');
    if (!neededSelector) {
      return;
    }
    const tooltip = document.createElement('div');
    tooltip.className = 'slider-tooltip';
    tooltip.innerText = 'Kolejna pożyczka';
    if (neededSelector && !neededSelector.contains(tooltip)) {
      neededSelector.append(tooltip);
    }
  }

  private removeFromDom() {
    const neededSelector = document.querySelector('.ng5-slider-span.ng5-slider-pointer.ng5-slider-pointer-min');
    if (!neededSelector) {
      return;
    }
    neededSelector.innerHTML = '';
  }
}
