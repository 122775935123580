import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {AppState} from '../../../../store/state';
import {Store} from '@ngrx/store';
import {CloseModal, OpenModal} from '../../../../store/actions';
import { ModalTypes } from 'src/app/enums';
import { parentPageContent } from 'src/app/store/selectors/info.selectors';
import { SetContentPage } from 'src/app/store/actions/info.actions';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-modal-content',
  templateUrl: './modal-content.component.html',
  styleUrls: ['./modal-content.component.scss']
})
export class ModalContentComponent implements OnInit {

  @Input()
  public closeBtnFixed = false;
  public parent: ModalTypes;

  public mobile = true;

  @Output()
  public onClose = new EventEmitter();

  constructor(private store: Store<AppState>,
              private deviceService: DeviceDetectorService) { }

  ngOnInit() {
    this.mobile = true; //  this.deviceService.isMobile();

    this.store.select(parentPageContent)
    .subscribe(data => {
      this.parent = data;
    });
  }

  public close() {

    if (this.parent !== ModalTypes.None)  {
      this.store.dispatch(new OpenModal(this.parent));
      this.store.dispatch(new SetContentPage({page: '', parent: ModalTypes.None}));
    } else {
      this.store.dispatch(new CloseModal());
    }

    this.onClose.emit();
  }

}
