import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationItem } from '../../../models';
import { AuthService, DataService } from '../../services';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../store/state';
import { LogOut, OpenModal } from '../../store/actions';
import { ModalTypes } from '../../enums';
import { Observable, combineLatest } from 'rxjs';
import { selectBankConfirmationsStatus, selectLoanCurrentInfo, selectUsername, selectUserNotifications } from '../../store/selectors';
import { filter, map } from 'rxjs/operators';
import { ClientLoanStateTypes } from 'src/app/modules/user-panel/models/client-loan-types';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input()
  public accountMenuItems: NavigationItem[];
  @Input()
  public menuItems: NavigationItem[];

  public isAuthorized = this.auth.isAuthorized$;
  public accountName$: Observable<string>;
  public userNotifications$: Observable<any[]>;
  public loan: any;
  public bankConfirmed$: Observable<boolean>;

  constructor(private dataService: DataService,
    private auth: AuthService,
    private router: Router,
    private store: Store<AppState>) {
    this.bankConfirmed$ = this.store.select(selectBankConfirmationsStatus);
    this.accountName$ = this.store.select(selectUsername);
    this.userNotifications$ = this.store.select(selectUserNotifications);
  }

  ngOnInit() {

    combineLatest([
      this.store.select(selectLoanCurrentInfo)
    ]).pipe(
      filter(values => Object.values(values).every(_ => _)),
      map((responses) => ({

        loanCurrentInfo: responses[0]
      })
      )
    ).subscribe(data => {
      if (data) {
        this.loan = {
          ...data.loanCurrentInfo,
          isWaitingDecision: (data.loanCurrentInfo.status === ClientLoanStateTypes.Requested ||
            data.loanCurrentInfo.status === ClientLoanStateTypes.Approved && data.loanCurrentInfo)
        };
      }
    });


  }

  public login() {
    if (this.router.url === '/logowanie') {
      this.store.dispatch(new OpenModal(ModalTypes.LoginModal));
    } else {
      this.router.navigateByUrl('/logowanie');
    }
  }

  public logout() {
    this.store.dispatch(new LogOut());
  }

  public goToNotifications() {
    this.router.navigate(['/profil/notifications-list']);
  }

  public navigate(url) {
    this.router.navigate([url]);
  }


  public isConfirmOfferPage() {
    return window.location.href.includes('/profil/potwierdz-wniosek');
  }

  public isConsentsPage() {
    return window.location.href.includes('/profil/consents');
  }

  public disableScroll() {
    document.body.classList.add('no-scroll');
  }
}
