import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {shareReplay} from 'rxjs/operators';
import {ApiService} from './api.service';
import {select, Store} from '@ngrx/store';
import {AppState} from '../store/state';
import {selectAuthenticationStatus} from '../store/selectors';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LoadUserData, LogOut, SetAuthenticationStatus} from '../store/actions';
import {parseJWT} from '../utils';
import { environment } from 'src/environments/environment';
import { globalVariables } from 'src/environments/globalVariables';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public isAuthorized$: Observable<boolean>;

  constructor(private http: ApiService,
              private httpClient: HttpClient,
              private authService: AuthService,
              private store: Store<AppState>) {
    this.isAuthorized$ = this.store.pipe(select(selectAuthenticationStatus)).pipe(shareReplay());
  }

  private SetNotAuthenticated() {
    this.store.dispatch(new SetAuthenticationStatus({isAuthenticated: false, token: null}));
  }

  public checkAuthState() {

    if (!navigator.cookieEnabled) {
      this.SetNotAuthenticated();
      return false;
    }

    if (localStorage.getItem('auth_token')) {
      if (window.localStorage.getItem('expired_token') &&
      (new Date()).getTime() < (Number(localStorage.getItem('expired_token')))) {
        localStorage.setItem('expired_token', (String)((new Date()).getTime() + environment.tokenExpireTime));
      } else {
        this.store.dispatch(new LogOut());
        this.SetNotAuthenticated();
      }}

    const token = this.getAuthToken();

    if (!token) {
        this.SetNotAuthenticated();
        return false;
      }
    this.store.dispatch(new LoadUserData({token}));
  }

  public login(userData: any): Observable<boolean> {
    return this.http.post(`${environment.AppID}/Login`, userData);
  }

  public getUserData(token: string) {

    if (this.getAuthToken()) {
    if (this.getExpiredToken() &&
    (new Date()).getTime() < (Number(this.getExpiredToken()))) {
      this.setExpiredToken((String)((new Date()).getTime() + environment.tokenExpireTime));
    } else {
      this.store.dispatch(new LogOut());
      this.store.dispatch(new SetAuthenticationStatus({isAuthenticated: false, token: null}));
    }}

    return this.http.post(`${environment.AppID}/GetPersonalInformation`, {
      accessToken: token
    }, this.getAuthHeaders(token));
  }

  // tslint:disable-next-line:align
  public getAuthToken(): string {

    if (navigator.cookieEnabled) {
      return localStorage.getItem('auth_token');
    } else {
      return globalVariables.auth_token;
    }
  }

  public setAuthToken(value: string) {
    if (navigator.cookieEnabled) {

      if (value == null) {
        localStorage.removeItem('auth_token');
      } else {
        localStorage.setItem('auth_token', value);
      }
    } else {
        globalVariables.auth_token = value;
    }
  }

    // tslint:disable-next-line:align
    public getExpiredToken(): string {

      if (navigator.cookieEnabled) {
        return localStorage.getItem('expired_token');
      } else {
        return globalVariables.expired_token;
      }
    }

    public setExpiredToken(value: string) {
      if (navigator.cookieEnabled) {
        if (value == null) {
          localStorage.removeItem('expired_token');
        } else {
          localStorage.setItem('expired_token', value);
        }
      } else {
        globalVariables.expired_token = value;
      }
    }

  public getTokenScope(): string {

    if (navigator.cookieEnabled && this.getAuthToken()) {
    if (this.getExpiredToken() &&
      (new Date()).getTime() < (Number(this.getExpiredToken()))) {
        this.setExpiredToken((String)((new Date()).getTime() + environment.tokenExpireTime));
    } else {
      this.store.dispatch(new LogOut());
      this.store.dispatch(new SetAuthenticationStatus({isAuthenticated: false, token: null}));
    }}

    return parseJWT(this.getAuthToken()).scope;
  }

  public getAuthHeaders(token?: string) {

    if (this.getAuthToken()) {
    if (this.getExpiredToken() &&
    (new Date()).getTime() < (Number(this.getExpiredToken()))) {
      this.setExpiredToken((String)((new Date()).getTime() + environment.tokenExpireTime));
    } else {
      this.store.dispatch(new LogOut());
      this.store.dispatch(new SetAuthenticationStatus({isAuthenticated: false, token: null}));
    }}

    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token ? token : this.authService.getAuthToken()}`
    });
  }

  private logoutAndResetData() {
    this.store.dispatch(new LogOut());
    this.store.dispatch(new SetAuthenticationStatus({isAuthenticated: false, token: null}));
  }

}
