import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {AppState} from '../../../../store/state';
import {Store} from '@ngrx/store';
import {ApiService} from '../../../../services';
import {FileService} from '../../../../services/file.service';
import saveAs from 'file-saver';

@Component({
  selector: 'app-filelist',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileListComponent implements OnInit {

  @Input()
  public docs: any[];
  public translations: Map<string, string>;

  constructor(private http: ApiService,
              private fileSaver: FileService,
              private store: Store<AppState>) {
  }

  ngOnInit() {
    this.translations = new Map([
      ['InformationForm', 'Formularz Informacyjny'],
      ['LoanConfirmation', 'Umowa Pożyczki'],
      ['CostTable', 'Tabela Kosztów'],
      ['LoanAgreement', 'Odstąpienie od Umowy Ramowej'],
      ['FrameworkAgreement', 'Odstąpienie od Umowy Pożyczki'],
      ['LoanAgreement', 'Umowa Ramowa Pożyczki']]);

    // this.store.dispatch(new LoadDocs(this.loanNumber));
    // this.docs$ = this.store.select(selectCertainDocs, {loanNumber: this.loanNumber});
  }

  public openInfo(id, filename) {
    this.fileSaver.loadDocument(id).subscribe(pdf => saveAs(pdf, `${filename}.pdf`));
  }
}
