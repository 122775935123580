export const showErrorsFromServer = (errors: any, formControls) => {
  for (const errProp in errors) {
    let neededControl;
    let neededControlName;
    if (errProp.includes('.')) {
      const innerProps = errProp.split('.').map(n => n && n.toLowerCase());
      neededControlName = Object.keys(formControls).find(propName => {
          return propName.toLowerCase() === innerProps[0];
        }
      );
      neededControl = formControls[neededControlName]['controls'][innerProps[1]];
    } else {
      neededControlName = Object.keys(formControls).find(propName => {
          return propName.toLowerCase() === errProp.toLowerCase();
        }
      );
      neededControl = formControls[neededControlName];
    }
    if (neededControl) {
      neededControl.setErrors({invalid: true, errMsg: errors[errProp].toString().replace(',', ', ')});
    }
  }
};
