import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {AuthService} from 'src/app/services/auth.service';
import {Observable, of} from 'rxjs';
import {HttpHeaders} from '@angular/common/http';
import {ClipboardService} from 'ngx-clipboard';



@Injectable({
  providedIn: 'root'
})
export class DataService {

  private apiUrl = environment.adminApiUrl;


  constructor(private http: HttpClient,
              private clipboardService: ClipboardService) {
    this.clipboardService.configure({ cleanUpAfterCopy: true });
  }

  public getData<T>(url) {
    /*if (url === '/api/getcomments') {
      return of(mockComments);
    }*/
    if (url === '/admin/getcontacts') {
      return of([
        {
          Phones: ['876 181 062', '222 282 340'],
          Emails: ['info@ratao.pl', 'windykacja@ratao.pl'],
        },
      ]);
    }

    if (url === '/api/account-menu') {
      return of([]);
    }

    return this.http.get<T>(this.apiUrl + url);
  }

  public updateData(url: string, parameters: any = {}): Observable<any> {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const options = {headers};
    return this.http.put(url, parameters, options);
  }

  public copyToClipBoard(data: any) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = data;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    this.clipboardService.copyFromContent(data);
    document.body.removeChild(selBox);
  }
}
