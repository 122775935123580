import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ModalTypes } from 'src/app/enums';
import { OpenModal } from 'src/app/store/actions';
import { SetContentPage } from 'src/app/store/actions/info.actions';
import { AppState } from 'src/app/store/state';

@Component({
  selector: 'app-footer-block',
  templateUrl: './app-footer-block.component.html',
  styleUrls: ['./app-footer-block.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterBlockComponent implements OnInit {

  constructor(private cookieService: CookieService,
    private store: Store<AppState>,
    private deviceService: DeviceDetectorService) {
  }

  private alertConfirmed = 'cookie-alert-confirmed';

  public dataCookieAlert = false;
  public mobile = false;


  ngOnInit() {

    this.mobile = this.deviceService.isMobile();

    if (navigator.cookieEnabled) {
      if (this.cookieService.get(this.alertConfirmed) === '') {
        this.dataCookieAlert = true;
      }
    }
  }

  // tslint:disable-next-line:variable-name
  public ShowContentModal(_page: string) {
    this.store.dispatch(new SetContentPage({ page: _page, parent: ModalTypes.None }));
    this.store.dispatch(new OpenModal(ModalTypes.Content));
  }

  public closeCookieAlert() {
    if (navigator.cookieEnabled) {
      this.cookieService.set(this.alertConfirmed, 'confirmed');
      this.dataCookieAlert = false;
    }
  }

  public hideCookieAlert() {
    this.dataCookieAlert = false;
  }

  public showCookieAlert() {
    this.dataCookieAlert = true;
  }

}
